.footer {
  background-color: $brand-secondary;
  padding-bottom: 35px;
  margin-top: 119px;

  .brand {
    display: inline-block;
    max-width: 240px;
    margin: -91px 0 12px;
  }

  a {
    font-size: 16px;
    padding: 0 32px;
  }
}

.copyright {
  background-color: #cfdfeb;
  padding: 12px 0 8px;

  *,
  a:hover {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Arial', sans-serif;
    color: #040606
  }
}