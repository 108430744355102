i.fa-file-pdf {
  margin-left: 3px;
}

.home-content {
  text-align: center;

  @include laptop {
    margin-bottom: 43px;
  }

  p {
    font-size: 18px;
  }
}

.home-blocks {
  background-size: cover;
  background-position: top;
  border-radius: 0 0 20px 20px;

  @include tablet {
    background-image: url('../images/underwater.png');
    padding: 71px 25px 25px;
  }

  @include laptop {
    padding: 71px 50px 50px;
  }

  @include wide {
    padding: 71px 127px 66px;
  }

  .block-col {
    display: flex;
    flex-direction: column;
  }

  .block {
    max-width: 415px;
    margin: 35px auto 0;
    flex-grow: 1;
  }
}

.buoys-blocks {
  background-image: none;

  @include laptop {
    background-image: url('../images/Lobster-Buoy2.jpg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding: 740px 30px 20px 50px;
  }

  @include desktop {
    padding: 760px 30px 20px 100px;
  }

  @include wide {
    padding: 740px 30px 20px 80px;
  }

  @include hd {
    padding: 860px 30px 80px 0;
  }

  .block {
    text-align: left;
    padding: 20px;
  }

  .buoys-wrapper {
    p {
      line-height: 1.2;
      font-size: 15px;
      margin-bottom: 10px;

      @include desktop {
        font-size: 17px;
        line-height: 1.2;
        margin-bottom: 10px;
      }

      @include wide {
        line-height: 1.3;
        margin-bottom: 14px;
      }

      @include hd {
        margin-bottom: 0.8rem;
      }
    }

    p.header {
      color: #5fee92;
      font-weight: 700;
      margin-bottom: 0;
      font-size: 19px;
    }
  }
}

.last-block,
.block {
  background-color: $brand-primary;
  border: 3px solid #48c675;
  border-radius: 20px;
  padding: 37px 41px;

  @include tablet {
    padding: 40px;
  }

  @include laptop {
    padding: 25px 30px;

    @include desktop {
      padding: 37px 23px 34px 41px;
    }
  }

  * {
    color: $white;
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
}

.last-block {
  text-align: center !important;
  float: none;
  margin-left: 0;

  @include laptop {
    float: right;
    margin-left: 0.5rem;
  }

  .brochure-card {
    padding: 15px;
    height: auto;

    .brochure-preview {
      border: 2px solid white;
      display: block;
      position: relative;
      margin: 0;
    
      &::before {
        content: '';
        background-color: rgba($black, 0.5);
    
        @include links-transition((visibility, opacity));
        @include stretch();
    
        @include screen(md) {
          visibility: hidden;
          opacity: 0;
        }
      }
    
      &::after {
        content: '\f1c1';
        font-family: 'Font Awesome 5 Pro', sans-serif;
        font-size: 25px;
        color: $white;
    
        @include center-absolute();
        @include links-transition((visibility, opacity));
    
        z-index: 300;
    
        @include screen(md) {
          content: '\f1c1';
          font-size: 35px;
          visibility: hidden;
          opacity: 0;
        }
      }
    
      img {
        margin: 0;
      }
    
      &:hover,
      &:focus {
        &::before,
        &::after {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.page-content-panel {
  a {
    color: $body-color;
    text-decoration: underline;
    text-underline-offset: 4px;

    &:hover {
      color: $link-color;
    }
  }
}

hr {
  border: 1px solid #d7e8f5;
  margin-top: 3.3rem;
  margin-bottom: 3.3rem;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;
}

.video-wrapper {
  background-color: black;
  color: white;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 225px;

  @include desktop {
    height: 265px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.bouy-image {
  width: 100%;

  @include laptop {
    width: 300px;
  }

  @include desktop {
    width: 350px;
  }
}