#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

.banner {
  position: relative;
  z-index: 0;
  height: 365px;

  @include laptop {
    height: 558px;
  }

  &::before {
    content: '';
    background-image: url('../images/header-bg.png');
    background-size: cover;
    background-position: bottom center;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    height: 435px;

    @include laptop {
      height: 635px;
    }
  }

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include laptop {
      display: block;
    }
  }

  .brand {
    max-width: 190px;

    @include laptop {
      max-width: 100%;
    }
  }

  .nav-primary {
    border-bottom: 2px solid #2faa63;
    width: fit-content;
  }
}

.nav-primary {
  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: $body-color;
    font-size: 15px;
    padding: 5px 30px;

    &:hover {
      color: $link-color;
    }
  }
}

.btn-red {
  margin-top: 50px;
  background-color: red;
  border-radius: 0;
  border: 1px solid black;

  &:hover {
    background-color: darken(red, 10%);
  }
}

a.btn-green {
  margin-top: 10px;
  background-color: #48c675;
  border: 1px solid white;
  color: white !important;
  text-decoration: none !important;
  max-width: 300px;

  @include desktop {
    margin-top: 10px;
    max-width: 400px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: darken(#48c675, 10%);
    border: 1px solid white;
  }
}

.menu-navigate-lobster-buoys {
  background-color: #48c675;
  border-radius: 10px;

  a {
    color: white;
  }
}